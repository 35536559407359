import React from "react"
import { navigate } from "gatsby"
import "./mystyles.scss"
import NameHeader from "../components/name-header"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

const SkillsPage = ({ data }) => {
  return (
    <div className="container">
      <Helmet>
        <title>Rusty Conover's Technology Experience</title>
        <html lang="en" />
      </Helmet>

      <div className="columns">
        <div className="column is-2"></div>

        <div className="column is-8">
          <NameHeader image={data.file.childImageSharp} />

          <section className="section is-small">
            <h2 className="title is-3">Technology Experience</h2>
            <div className="is-6">
              I've worked with a varied list of technologies. This inventory is
              always expanding, as I'm always learning and exploring new ideas.
            </div>
          </section>

          <section className="section">
            <h3 className="title is-4">Programming Languages</h3>
            <div className="content">
              <table className="table is-hoverable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="is-hidden-mobile is-hidden-tablet">
                      Experience (Years)
                    </th>
                    <th className="is-hidden-mobile is-hidden-tablet">
                      Proficiency
                    </th>
                    <th>I've used it for:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>C</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      24
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      I've used it for almost everything since it is so
                      fundamental to Unix and most other software libraries.
                    </td>
                  </tr>
                  <tr>
                    <td>Python</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      12
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      <p>
                        I've built multiple large sized projects using Python,
                        but I've also used Python what everyone else uses it for
                        Tensorflow, Numpy, Pandas, and Scikit. I've also used
                        Django and enjoy using Jupyter notebooks.
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>JavaScript</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      15
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      <p>
                        I've contributed to Node.js, and have published multiple
                        NPM packages.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>TypeScript</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      5
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      <p>
                        TypeScript is my favorite prototyping language; most of
                        my new projects start in it.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Rust</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      3
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      I've shipped some open-source Rust crates and still have a
                      few stealth projects yet to ship
                    </td>
                  </tr>
                  <tr>
                    <td>SQL</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      18
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      <p>
                        I have a preference for PostgreSQL. I've contributed and
                        have followed with its development since version 7.2.
                        I'm familiar with extension creation, data type design,
                        PostgreSQL internals, and various scaling issues.
                      </p>
                      <p>
                        I've also used DB2, Oracle, SQLite, MySQL, and MariaDB.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>NoSQL</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      6
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      I've also used many NoSQL products they include: DynamoDB,
                      LevelDB, MongoDB, Cassandra, and Riak
                    </td>
                  </tr>
                  <tr>
                    <td>C++</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      14
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      <p>
                        I've used it with Boost and the STL. Most recently, I've
                        contributed to the Node.js backend in C++.
                      </p>
                      <p>For new projects, I'm using Rust instead.</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Perl</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      18
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      Creating large projects and various open-source modules.
                      I've used Catalyst, Moose, Dancer, Moo, Moops, and even
                      Kavorka.
                    </td>
                  </tr>
                  <tr>
                    <td>Java</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      15
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient/Expert
                    </td>
                    <td>
                      <p>
                        I'm familiar with the internals of the JVM, especially
                        garbage collection.
                      </p>

                      <p>
                        Two Sigma used Java extensively. I've felt the pain of
                        scaling Netty. I've dealt with debugging maven and ant
                        build failures. I've also used Solr and Lucene.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>PHP</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      7
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      <p>
                        I've created extensions for PHP and have written a
                        module or two.
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>Bash / Shell Scripting</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      6
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      <p>
                        I've used bash to glue things together in short scripts,
                        but I prefer my shell to be zsh.
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>R</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      3
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      <p>
                        I've used R for statistical analysis and visualizations.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>Julia</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      1
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Learning
                    </td>
                    <td>
                      <p>
                        I'm just starting to consider Julia as an alternative to
                        Python since it can run much faster.
                      </p>
                      <p>I've written and published modules for Julia.</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Prolog</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      3
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>Solving some optimization problems</td>
                  </tr>
                  <tr>
                    <td>C#</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      2
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      I've implemented a few apps using WPF, WinForms, VSTO, and
                      COM.
                    </td>
                  </tr>
                  <tr>
                    <td>Objective C</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      3
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      I shipped an iOS App. I'm familiar with various frameworks
                      like AppKit and UIKit.
                    </td>
                  </tr>
                  <tr>
                    <td>Swift</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      1
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>I've used this for development on iOS.</td>
                  </tr>
                  <tr>
                    <td>Scala</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      1
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      I learned this to explore functional programming on the
                      JVM, it has fallen out of my favor, especially as
                      functional programming has come to JavaScript and
                      TypeScript.
                    </td>
                  </tr>
                  <tr>
                    <td>Go</td>
                    <td className="is-hidden-mobile is-hidden-tablet has-text-right">
                      2
                    </td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>I've modified Docker and the Docker repository.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section className="section">
            <h3 className="title is-4">Machine Learning / Data Science </h3>
            <div className="content">
              <p>
                Around 2015, I started to get interested in data science and
                machine learning. I've grown familiar with:
              </p>

              <table className="table is-hoverable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>I've used it for:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Apache Arrow</td>
                    <td>
                      Arrow is my preferred means of processing data at scale.
                    </td>
                  </tr>
                  <tr>
                    <td>DuckDB</td>
                    <td>
                      DuckDB is a new SQL database that I've been using for
                      analyzing data.
                    </td>
                  </tr>
                  <tr>
                    <td>Apache Iceberg</td>
                    <td>
                      Apache Iceberg is my preferred means of storing data at
                      scale, its transactional semantics are very useful.
                    </td>
                  </tr>
                  <tr>
                    <td>Delta Lake</td>
                    <td>
                      I've used Delta Lake and Delta Live Tables for processing
                      streaming data.
                    </td>
                  </tr>
                  <tr>
                    <td>Apache Hudi</td>
                    <td>
                      I've evaluated Apache Hudi for processing streaming data.
                    </td>
                  </tr>
                  <tr>
                    <td>Tensorflow</td>
                    <td>
                      I've used Tensorflow to build models for classification
                      and regression. I've also used Tensorflow Probability to
                      model distributions.
                    </td>
                  </tr>
                  <tr>
                    <td>scikit-learn</td>
                    <td>
                      I've used scikit-learn's optimizers and tree-based models.
                    </td>
                  </tr>
                  <tr>
                    <td>Numpy</td>
                    <td>
                      Without the routines from Numpy, machine learning would be
                      very slow.
                    </td>
                  </tr>
                  <tr>
                    <td>Pandas</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>xgboost</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <p>
                Aside from all of those modules, machine learning continues to
                be one of my interests; I'm especially interested in
                reinforcement learning and time series forecasting, Bayesian
                statistics, and optimization.
              </p>
            </div>
          </section>

          <section className="section">
            <h3 className="title is-4">Cloud Computing / Cloud Providers </h3>
            <div className="content">
              <p>
                I've developed a unique hands-on understanding of the
                capabilities of public cloud providers.
              </p>

              <p>The AWS services that I'm very familiar with include:</p>

              <table className="table is-hoverable">
                <thead>
                  <tr>
                    <th>Service Name</th>
                    <th>I've used it for:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>EC2 / VPCs</td>
                    <td>
                      spot instances, auto-scaling, VPCs, security groups,
                      network access controls NAT gateways, VPC peering, VPC
                      endpoints, Load Balancers, AWS Direct Connect, AMIs, DNS,
                      Metadata services. I've used almost every feature
                      associated with EC2 and VPCs
                    </td>
                  </tr>
                  <tr>
                    <td>S3</td>
                    <td>
                      I've stored and transferred petabytes of data from S3;
                      I've also maintained a Perl client for S3.
                    </td>
                  </tr>
                  <tr>
                    <td>CloudFront</td>
                    <td>
                      I've used CloudFront as a CDN for both video streaming and
                      websites; I'm also very familiar with its Lambda@Edge
                      capabilities.
                    </td>
                  </tr>
                  <tr>
                    <td>API Gateway</td>
                    <td>
                      I've written and deployed APIs at AWS utilizing API
                      Gateway as the front end.
                    </td>
                  </tr>
                  <tr>
                    <td>AWS Lambda</td>
                    <td>
                      I believe in operating as little infrastructure as
                      necessary; Lambda's flexibility to enable the "serverless"
                      architecture is amazingly useful.
                    </td>
                  </tr>
                  <tr>
                    <td>AWS Step Functions</td>
                    <td>
                      I've used this to orchestrate workloads and AWS Lambda
                      functions
                    </td>
                  </tr>
                  <tr>
                    <td>AWS Batch / ECS</td>
                    <td>
                      I've run millions of AWS Batch jobs, its a great
                      orchestration framework.
                    </td>
                  </tr>
                  <tr>
                    <td>Elastic Container Registry</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>IAM / KMS / SAML</td>
                    <td>
                      I'm very familiar with roles, policies, users, groups,
                      identity providers (IDPs), and SAML authentication
                    </td>
                  </tr>
                  <tr>
                    <td>CloudFormation</td>
                    <td>
                      I've specified and reviewed my cloud infrastructure and
                      policies using CloudFormation often
                    </td>
                  </tr>
                  <tr>
                    <td>Kinesis / Kinesis Firehose</td>
                    <td>
                      I've used these services to handle streaming data,
                      restructure it, and ingest it into analytics pipelines.
                    </td>
                  </tr>
                  <tr>
                    <td>AWS Athena</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Cognito</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>RDS / Redshift / AWS Aurora</td>
                    <td>
                      I've run many databases on RDS and analytics workloads
                      utilizing Redshift.
                    </td>
                  </tr>
                  <tr>
                    <td>CodeCommit / CodeBuild / CodePipeline</td>
                    <td>
                      I've utilized and setup an entire automated build
                      processes.
                    </td>
                  </tr>
                  <tr>
                    <td>IoT Core</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>SQS</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>SES</td>
                    <td>
                      I've sent and received emails programmatically with SES
                    </td>
                  </tr>
                  <tr>
                    <td>ElastiCache</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>DynamoDB</td>
                    <td>
                      I've used DynamoDB extensively for many projects. I've
                      written a Perl based client for DynamoDB. I've also
                      provided enhancements to various Node.js modules that
                      interact with DynamoDB.
                    </td>
                  </tr>
                  <tr>
                    <td>WorkSpaces / WorkMail</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <p>The Google Cloud services with which I'm familiar with are:</p>

              <ul>
                <li>Cloud CDN</li>
                <li>Cloud Run</li>
                <li>Cloud SQL</li>
                <li>Compute Engine</li>
                <li>Vision AI</li>
                <li>Pub/Sub</li>
              </ul>
            </div>
          </section>

          <section className="section">
            <h3 className="title is-4">Video Streaming</h3>
            <div className="content">
              <p>
                These technologies were things I used when building a scalable
                video streaming platform:
              </p>
              <ul>
                <li>HLS - HTTP Live Streaming</li>
                <li>H.264 / VP8 / VP9</li>
                <li>RTMP / RTSP</li>
                <li>CDNs - Content Delivery Networks</li>
              </ul>
            </div>
          </section>

          <section className="section">
            <h3 className="title is-4">
              Web / Internet Protocols and Technologies
            </h3>
            <div className="content">
              I've been using open source technologies and open protocols since
              the start of my career.
              <table className="table is-hoverable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="is-hidden-mobile is-hidden-tablet">
                      Proficiency
                    </th>
                    <th>I've used it for:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>TCP / UDP</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      I've used it to move petabytes of data around the world.
                    </td>
                  </tr>
                  <tr>
                    <td>HTTP 1.0/1.1</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      Almost everything. I've implemented numerous proxies. I
                      know all about cookies, headers, authentication, content,
                      and transfer encodings. I've also implemented many web
                      crawlers that have subsequently requested and processed
                      billions of pages.
                    </td>
                  </tr>
                  <tr>
                    <td>HTML / CSS</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>Building and parsing website content.</td>
                  </tr>
                  <tr>
                    <td>TLS / OpenSSL</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      Keeping data encrypted from prying eyes. I've dealt with
                      cipher suite selection, session resumption, and various
                      other extensions. I understand PKI.
                    </td>
                  </tr>
                  <tr>
                    <td>XML</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      Services often use XML, I've parsed it, and I've produced
                      it as output. I've also used XPath and XSLT to extract and
                      transform it into a more usable state.
                    </td>
                  </tr>
                  <tr>
                    <td>JSON</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      I've used JSON for services that don't use XML. I've
                      written and validated JSON schemas, I've also dealt with
                      streaming JSON parsing and querying.
                    </td>
                  </tr>
                  <tr>
                    <td>SOAP / XML-RPC</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>GraphQL</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>WebRTC / Web sockets</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      I've implemented real-time notifications for applications
                      using web sockets.
                    </td>
                  </tr>
                  <tr>
                    <td>DNS</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      I operated DNS servers for a few decades, but now I let
                      AWS operate them for me. I know about writing zone files
                      and zone delegation.
                    </td>
                  </tr>
                  <tr>
                    <td>Redis</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      I've used Redis as a key-value store and more of its
                      advanced data structures
                    </td>
                  </tr>
                  <tr>
                    <td>Memcached</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      Memcached is a great key-value store, but I've had a few
                      ideas about a different approach.
                    </td>
                  </tr>
                  <tr>
                    <td>SMTP / POP3 / IMAP</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Expert
                    </td>
                    <td>
                      I've run my own SMTP/POP3/IMAP servers for over a decade.
                      It's a struggle; now I just let others handle it for me.
                    </td>
                  </tr>
                  <tr>
                    <td>SAML / OpenID</td>
                    <td className="is-hidden-mobile is-hidden-tablet">
                      Proficient
                    </td>
                    <td>
                      I've successfully implemented federated authentication for
                      multiple projects.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section className="section">
            <h3 className="title is-4">Operating Systems</h3>
            <div className="content">
              I'm very comfortable and productive in any Unix based environment.
              I'm very proficient using:
              <ul>
                <li>Linux (Fedora, Debian, RedHat, Slackware), Solaris, AIX</li>
                <li>Mac OS X, iOS</li>
                <li>Windows</li>
              </ul>
            </div>
          </section>

          <div className="has-text-centered">
            <button
              onClick={event => {
                event.preventDefault()
                navigate("/")
              }}
              className="button is-black is-rounded"
            >
              {" "}
              <span className="icon">
                <i className="fas fa-backward"></i>
              </span>
              &nbsp;&nbsp; View Résumé
            </button>
          </div>
        </div>
      </div>
      <div className="column is-2"></div>
    </div>
  )
}

export const query = graphql`
  query SkillsImageQuery {
    file(name: { regex: "/rusty-river-full-round/" }) {
      childImageSharp {
        gatsbyImageData(width: 128, placeholder: NONE, layout: FIXED)
      }
    }
  }
`

export default SkillsPage
